import { createStore } from 'vuex'

export default createStore({
  state: {
    placesCount: 1
  },
  getters: {
  },
  mutations: {
    placesCount: (state, value) => {
      state.placesCount = value;
    }
  },
  actions: {
  },
  modules: {
  }
})
