import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueGtag from "vue-gtag";

import Chapter from "@/components/Chapter.vue";
import Checklist from "@/components/Checklist.vue";
import ColorLayer from "@/components/ColorLayer.vue";
import ContactForm from "@/components/ContactForm.vue";
import CoworkingCalculator from "@/components/CoworkingCalculator.vue";
import DigitsBanner from "@/components/DigitsBanner.vue";
import ImageMobileTextLayer from "@/components/ImageMobileTextLayer.vue";
import ImageStack from "@/components/ImageStack.vue";
import More from "@/components/More.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import SimpleDigit from "@/components/SimpleDigit.vue";
import SliderLayer from "@/components/SliderLayer.vue";
import TitleBlock from "@/components/TitleBlock.vue";
import TitleBlockImage from "@/components/TitleBlockImage.vue";
import TitleLayer from "@/components/TitleLayer.vue";
import {CMSComponent, CMSISpan} from "@snark/cms-ssr";

const app = createApp(App);

app.use(store);
app.use(router);

// --- GTAG
const gtagOptions = {
    config: { id: process.env.VUE_APP_GTAG },
    includes: [
        { id: process.env.VUE_APP_GOOGLE_ADS },
    ]
};
app.use(VueGtag, gtagOptions, router);

// --- CMS
app.component("ispan", CMSISpan);
app.component("cms-component", CMSComponent);

// --- Layers
app.component("ColorLayer", ColorLayer);
app.component("ContactForm", ContactForm);
app.component("CoworkingCalculator", CoworkingCalculator);
app.component("DigitsBanner", DigitsBanner);
app.component("ImageMobileTextLayer", ImageMobileTextLayer);
app.component("SliderLayer", SliderLayer);
app.component("TitleLayer", TitleLayer);
app.component("TitleBlock", TitleBlock);
app.component("TitleBlockImage", TitleBlockImage);

// --- utilities
app.component("Checklist", Checklist);
app.component("Chapter", Chapter);
app.component("ImageStack", ImageStack);
app.component("More", More);
app.component("ProgressBar", ProgressBar);
app.component("SimpleDigit", SimpleDigit);

app.mount('#app');
