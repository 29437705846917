import {v4 as uuid} from "uuid";

export type ResizeCallback = (width: number, height: number) => void;
export type ResizeObserver = {
    id: string
    el: HTMLElement
    callback: ResizeCallback
    width: number
    height: number
}

const resizeIdAttribute = "__resize_directive_id";

let resizeObservers: ResizeObserver[] = [];

function addObserver(id: string, el: HTMLElement, callback: ResizeCallback) {
    resizeObservers.push({id, el, callback, width: 0, height: 0});
}

function getObserver(id: string|null): ResizeObserver|undefined  {
    if(id) {
        return resizeObservers.find(obs => (obs.id === id));
    }
    else {
        return undefined;
    }
}

function removeObserver(id: string|null) {
    if(id) {
        const index = resizeObservers.findIndex(obs => (obs.id === id));
        if (index >= 0) {
            resizeObservers.splice(index, 1);
        }
    }
}

function setelementSize(id: string|null, width: number, height: number) {
    const obs = getObserver(id);
    if(obs) {
        if((obs.width !== width) || (obs.height !== height)) {
            obs.width = width;
            obs.height = height;
            obs.callback(width, height);
        }
    }
}

function resizeHandler() {
    for(const obs of resizeObservers) {
        setelementSize(obs.id, obs.el.clientWidth, obs.el.clientHeight);
    }
}

if(globalThis && globalThis.window) {
    window.addEventListener("resize", resizeHandler);
}


const vResize = {
    created: (el: HTMLElement, binding: any) => {
        const id = uuid();
        el.setAttribute(resizeIdAttribute, id);
        addObserver(id, el, binding.value);
    },
    mounted: (el: HTMLElement) => {
        setelementSize(el.getAttribute(resizeIdAttribute), el.clientWidth, el.clientHeight);
    },
    updated: (el: HTMLElement) => {
        setelementSize(el.getAttribute(resizeIdAttribute), el.clientWidth, el.clientHeight);
    },
    beforeUnmount: (el: HTMLElement) => {
        removeObserver(el.getAttribute(resizeIdAttribute));
    }
};

export default vResize;