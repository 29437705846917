import {CMSConfiguration} from "@snark/cms-ssr";
import moment from "moment-timezone";

const config: CMSConfiguration = {
    cookieConsent: {
        enabled: true,
        text: "Ce site utilise des cookies pour améliorer l'expérience utilisateur. Toutes les données sont utilisées de manière anonyme.",
        buttonLabel: "Ok, ca va !"
    },
    layouts: {
        main: {
            _component: "snark-main-layout",
            menu: "disabled",
            footer: {
                noHomeLink: true,
                parts: [
                    "36 bis cours Maréchal Juin, 33000 Bordeaux",
                    // {text: "Contact", link: "contact"},
                    // {text: "Mentions légales", link: "legals"}
                ],
                socialNetworks: [
                    {type: "instagram", url: "https://www.instagram.com/snark_factory"},
                    {type: "linkedin", url: "https://www.linkedin.com/company/snarkfactory/about"}
                ]
            }
        },
        home: {
            _component: "snark-home-layout"
        }
    },
    pageLayouts: {
        horizontalLayers: {
            _component: "cms-horizontal-layers"
        },
        snarkHorizontalLayers: {
            _component: "snark-page-layout-horizontal-layers"
        },
        horizontalSlider: {
            _component: "snark-page-layout-hslider"
        }
    },
    navigation: {
        mainMenu: [
            // {name: "home", label: "accueil"},
        ]
    },
    pages: {
        home: {
            _path: "/",
            _layout: "home",
            _pageLayout: "horizontalSlider",
            layers: [
                {
                    _component: "TestHomeContent",
                    test: "a component without _component."
                }
            ]
        },
        techs: {
            _path: "/nos-technos",
            _layout: "main",
            _pageLayout: "horizontalLayers",
            layers: [
                {
                    _component: "unknown",
                    test: "Page Techs"
                }
            ]
        },
        coworking: {
            _path: "/coworking",
            _layout: "main",
            _pageLayout: "snarkHorizontalLayers",
            title: "Coworking",
            category: "Collaboration",
            layers: [
                {
                    _component: "TitleLayer",
                    layout: {pageHeight: true},
                    chapter: "espace de travail",
                    chapterRank: 0,
                    title: "<em>Snark Factory,</em>c'est aussi un espace de coworking",
                    more: "en savoir plus",
                    moreAction: "next-layer",
                    cta: "Je vous contacte direct !",
                    ctaAction: {target: "contact"},
                    imageComponent: "ImageStack",
                    images: [
                        "/img/coworking/table.png",
                        "/img/coworking/chair.png"
                    ]
                },
                {
                    _component: "TitleBlockImage",
                    layout: {pageHeight: true},
                    title: "espace de travail",
                    subtitle: "coworking",
                    content: "Situé au 36 bis Cours Maréchal Juin, en plein centre ville, à côté de l'arrêt Palais de Justice du Tram A et des lignes de bus " +
                        "1, 4, 5, 12, 15, 16 et 20 ; Snark Factory offre un espace de coworking de 500 m&sup2; ouvert et accueillant une cinquantine de bureaux, " +
                        "2 salles de réunions, un espace pour garer son vélo et un espace central commun de 120 m&sup2; complètement modulaire.",
                    footer: "500 m&sup2; au coeur de Bordeaux",
                    image: "/img/coworking/cowork-06.jpg"
                },
                {
                    _component: "DigitsBanner",
                    chapterRank: 1,
                    chapter: "En nombre",
                    title: "Votre bureau fixe<br/>comprend",
                    columns: [
                        {
                            _component: "SimpleDigit",
                            value: "1",
                            text: "table + fauteuil attitrés. Vous organiser votre espace comme bon vous semble. On peut même bouger les tables&nbsp;!"
                        },
                        {
                            _component: "SimpleDigit",
                            value: "120 m<sup>2</sup>",
                            text: "D’espace central entièrement modulable : canapés, bar de 6m, espace cuisine, frigos, grand écran, ..."
                        },
                        {
                            _component: "SimpleDigit",
                            value: "50",
                            text: "Bureaux situés tout autour de l'espace central dans des alcoves ou plus ouvert en fonction de vos préférences."
                        },
                        {
                            _component: "Checklist",
                            title: "Également compris",
                            list: [
                                "Internet Fibre",
                                "Accès 24h/24 7j/7",
                                "Accès illimité à 2 salles de réunion multi-usages",
                                "Une imprimante/scanner",
                                "Espace central à disposition",
                                "Un point cuisine",
                                "L'électricité",
                                "Des coworkers hyper sympa&nbsp;!"
                            ]
                        }
                    ]
                },
                {
                    _component: "CoworkingCalculator",
                    chapterRank: 2,
                    chapter: "Tarifs",
                    title: "Calculer votre prix",
                    base: "Base de prix pour un poste",
                    priceSuffix: "HT/mois",
                    comment: "Tarifs dégressif en fonction du nombre de postes",
                    price: 250,
                    max: 50,
                    default: 1,
                    monthTotal: "Total mensuel",
                    placePrice: "Tarif au poste",
                    image: "/img/coworking/cowork-07.jpg"
                },
                {
                    _component: "SliderLayer",
                    title: "Des espaces multiples",
                    text: "Votre poste est attitré, le reste de l'espace est commun et s'auto organise dans le respect et la bonne humeur.",
                    images: [
                        "/img/coworking/cowork-02.jpg",
                        "/img/coworking/event-04.jpg",
                        "/img/coworking/event-02.jpg",
                        "/img/coworking/cowork-01.jpg",
                        "/img/coworking/event-03.jpg",
                        "/img/coworking/event-01.jpg",
                        "/img/coworking/cowork-04.jpg",
                    ]
                },
                {
                    _component: "ColorLayer",
                    title: "Modalités<br/>d'accueil",
                    text: "C'est super simple ! Vous nous contactez, vous venez visiter l'espace et quand vous êtes prêt, vous emménagez. Le montant du 1er mois est calculé au prorata de la date d'entrée et les mois suivants sont à régler au début du mois comme n'importe quel loyer. Pas de préavis, si vous nous quittez (snif.). Pas d'engagement.",
                    image: "/img/coworking/cowork-03.jpg",
                    colors: ["#EB452E", "#244F6E", "#603813", "#FAAF3B"]
                },
                {
                    _component: "ImageMobileTextLayer",
                    title: "Venez, c'est sympa",
                    text: "A Snark Factory, on travaille mais on sait aussi s'amuser ! Des apéros, une newsletter pour partager pleins de choses, des olympiades, un secret santa, des compétitions de jeu vidéo, des combats de nerf pour les plus fous, et plein d'autres choses à découvrir et proposer...",
                    image: "/img/coworking/soiree-01.jpg"
                },
                {
                    _component: "ContactForm",
                    _name: "contact",
                    api: process.env.VUE_APP_API_URL + "/coworking/contact"
                }
            ]
        },
        projects: {
            _path: "/nos-projets",
            _layout: "main",
            _pageLayout: "horizontalLayers",
            layers: [
                {
                    _component: "unknown",
                    test: "Page Projets"
                }
            ]
        },
        contact: {
            _path: "/contactez-nous",
            _layout: "main",
            _pageLayout: "horizontalLayers",
            layers: [
                {
                    _component: "unknown",
                    test: "Page Contact"
                }
            ]
        },
        about: {
            _path: "/a-propos-de-snark",
            _layout: "main",
            _pageLayout: "horizontalLayers",
            layers: [
                {
                    _component: "unknown",
                    test: "Page A propos de snark"
                }
            ]
        },
        legals: {
            _path: "/legales",
            _layout: "main",
            _pageLayout: "horizontalLayers",
            layers: [
                {
                    _component: "unknown",
                    test: "Page mentions legales"
                }
            ]
        },
        learning: {
            _path: "/nos-formations",
            _layout: "main",
            _pageLayout: "horizontalLayers",
            layers: [
                {
                    _component: "unknown",
                    test: "Page Formations"
                }
            ]
        }
    },
    data: {
        _global: {
            defaultLayoutTexts: {
                rightTop: "techs",
                leftBottom: "studio digital",
                rightBottom: moment().format("YYYY")
            }
        }
    }
};

export default async function getConfig() : Promise<CMSConfiguration> {
    return config;
}
